<template>
    <div class="Site PageBox">
      <NavBar :title="$t('common2[1]')" :page-number="-1"/>
        <!--    <van-image width="100%" fit="contain" src="./static/images/customer.png" />-->
        <div class="ScrollBox" style="margin: 0 auto;text-align: center;margin-top: 46px">
          <div class="Panel" style="height: 84px;background-color:var(--bc-text-color-5); margin-top: 0px;display: flex;width: 95%;padding: 10px;margin-left: 10px" v-if="service_url2.length>0" @click="gotoService(2)">
            <div style="display: flex; align-items: center;">
              <div style="margin-left: 20px;">
                <img src="~@/assets/img/skinbitcoin/11130.png"/>
              </div>
              <div style="line-height: 30px;margin-left: 20px; font-size: 20px;color: var(--bc-text-color-1);"> {{$t('common2[3]')}} </div>
            </div>
          </div>
          <div class="Panel" style="height: 84px;background-color:var(--bc-text-color-4); margin-top: 0px;display: flex;width: 95%;padding: 10px;margin-left: 10px" v-if="service_url.length>0" @click="gotoService(1)">
            <div style="display: flex; align-items: center;">
              <div style="margin-left: 20px;">
                <img src="~@/assets/img/skinbitcoin/11131.png"/>
              </div>
              <div style="line-height: 30px;margin-left: 20px; font-size: 20px; color: var(--bc-text-color-1);">
                {{$t('common2[4]')}}

              </div>
            </div>
          </div>
          <div v-if="service_url3.length>0" style="margin-top: 30px" @click="gotoService(3)">
            <div>
              <img src="/skinlscc/customer.png" height="60px"/>
            </div>
            <div style="margin-top:-30px"> {{$t('common2[4]')}}3</div>
          </div>
            <div v-show="showAddress" class="showAdd">
                <div style="display: flex">
                    <div>
                        <van-button style="margin-right: 10px;height: 30px" size="normal" color="var(--button_bg)" @click="copyCode(qr_txt)"
                                    class="copy-qb">{{ $t('invite[3]') }}</van-button>
                    </div>
                    <div style="margin: 20px;color:var(--font_color)">{{qr_txt}}</div>
                </div>
                <img :src="qr_url" style="width: 150px;height: 150px"/>
                <div style="height: 40px;"/>
            </div>
        </div>
        <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
    </div>
</template>
<script>
    export default {
        name: 'Service',
        components: {

        },
        props: {},
        data() {
            return {
                showAddress:false,
                service_url:'',
                service_url2:'',
                service_url3:'',
                qr_urls:[],
                qr_txt:'',
                qr_url:'',
            }
        },
        computed: {
        },
        watch: {

        },
        created() {

        },
        mounted() {
            this.qr_urls.push(this.InitData.setting['WeChat_official']);
          this.qr_urls.push(this.InitData.setting['seal_img']);
          this.qr_urls.push(this.InitData.setting['service_hotline']);
            this.service_url = this.InitData.setting.service_url
            this.service_url2 = this.InitData.setting.service_url2;
          this.service_url3 = this.InitData.setting.service_url3;
        },
        activated() {

        },
        destroyed() {

        },
        methods: {
          copyCode(text) {
            var copyBox = document.getElementById("inputBox");
            copyBox.value = text;
            copyBox.select();
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$Dialog.Toast(this.$t('recharge.label[5]'));
          },
            gotoService(index){
            this.showAddress = false;
            var service = ''
            if (index==1){
              service = this.InitData.setting.service_url;
            }else if (index==2){
              service = this.InitData.setting.service_url2;
            }else if (index==3){
              service = this.InitData.setting.service_url3;
            }
            if (service.indexOf("http")>=0){
              window.location.href = service
            }else{
              this.showAddress = true;
              this.qr_txt = service;
              this.qr_url = this.qr_urls[index-1];
            }
            },
            savePicture(){
                var Url= this.service_hotline;
                var blob=new Blob([''], {type:'application/octet-stream'});
                var url = URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = Url;
                a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0];
                var e = document.createEvent('MouseEvents');
                e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                URL.revokeObjectURL(url);
            },
            saveImg(){
                var fileName = "service1code.png"
                let canvas = this.qrcode._el.querySelector("canvas");//获取生成二维码中的canvas，并将canvas转换成base64
                var base64Text = canvas.toDataURL("image/png");
                var blob = this.getBlob(base64Text);
                if (navigator.msSaveBlob) {
                    // IE的Blob保存方法
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    var link = document.createElement('a');
                    var href = window.URL.createObjectURL(blob);
                    link.href = href;
                    link.download = fileName;  //a标签的下载属性
                    document.body.appendChild(link); // firefox需要把a添加到dom才能正确执行click
                    link.click();
                    // 延时保证下载成功执行，否则可能下载未找到文件的问题
                    setTimeout(function () {
                        window.URL.revokeObjectURL(href); // 释放Url对象
                        document.body.removeChild(link);
                    }, 100);
                }

            },
            getBlob(base64) {
                var mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // mime类型
                var byteString = atob(base64.split(',')[1]); //base64 解码
                var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
                var intArray = new Uint8Array(arrayBuffer); //创建视图
                for (var i = 0; i < byteString.length; i += 1) {
                    intArray[i] = byteString.charCodeAt(i);
                }
                return new Blob([intArray], {
                    type: mimeString
                });
            },
        }
    }
</script>
<style scoped>
    .PageBox {
        padding-top: 40px;
    }
    .ScrollBox{

    }

    .showAdd {
        background: var(--panel_color);
        border-radius: 10px;
        color: #d7d7d7;
        margin: auto;width: 95%;padding: 20px;text-align: center;
        margin-top: 30px;

    }
    .van-grid-item__content {
        padding: 10px !important;
        display: flex;
        color: #D7D7D7;
        background-color:var(--panel_color);
    }

    .listPanel{
        margin: 10px;
        border-radius: 10px;

        padding: 5px;
    }
    .van-cell {
        padding: 17px 0;
        padding-left: 20px;

    }

    .van-cell__title {

    }
    .van-cell>>>.van-icon{
        font-size: 20px;
        color: #89BFC1;
    }
    .QRCode {
        margin-left: 10px;
        height: 230px;
    }

</style>
